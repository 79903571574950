import * as React from 'react'

import { Container, Typography, Grid, makeStyles, Theme, createStyles, Button, Paper, useMediaQuery, useTheme } from '@material-ui/core'
import { useStaticQuery, graphql, Link } from 'gatsby'
import IndexLayout from '../layouts'
import Hero from '../components/Hero'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    text: {
      textAlign: 'center'
    },
    section: {
      marginTop: '5vh'
    },
    rsvpButton: {
      width: '50px'
    },
    orangeryImage: {
      width: '100%'
    },
    buttonText: {
      textDecoration: 'none'
    },
    simpleStepsGrid: {
      marginTop: '3vh'
    },
    simpleStepsButtonMobile: {
      width: '100%'
    },
    simpleStesButtonsDesktop: {},
    simpleStepsButtonParentMobile: {
      width: '100%',
      display: 'grid'
    },
    simpleStepsButtonParentDesktop: {}
  })
)
const IndexPage = () => {
  const classes = useStyles()
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up('sm'))
  return (
    <IndexLayout>
      <Hero />
      <Container maxWidth="md">
        <Grid container alignItems="center" justify="center" direction="column">
          <Grid item container direction="column" alignItems="center" justify="center" className={classes.section}>
            <Typography variant="h3" className={classes.text} paragraph>
              Our Story
            </Typography>
            <Typography role="img" aria-label="UCL Portico" variant="h2" paragraph className={classes.text}>
              🏛
            </Typography>
            <Typography paragraph className={classes.text}>
              What started as a chance meeting in a physics lab at UCL in 2013
            </Typography>
            <Typography role="img" aria-label="Helicopter and Glacier" variant="h2" paragraph className={classes.text}>
              🚁🏔
            </Typography>
            <Typography paragraph className={classes.text}>
              Lead to a surprise proposal on top of a glacier on the other side of the world. (Which you can view{' '}
              <Link to="/proposal-video/">here</Link>)
            </Typography>
            <Typography role="img" aria-label="Bride and Groom" variant="h2" paragraph className={classes.text}>
              👰🤵
            </Typography>
            <Typography paragraph className={classes.text}>
              We're so looking forward to starting this new chapter together, and can't wait to celebrate with you
            </Typography>
          </Grid>
          <Grid item container direction="column" alignItems="center" justify="center" className={classes.section}>
            <Typography variant="h3" className={classes.text} paragraph>
              The Wedding
            </Typography>
            <Typography className={classes.text}>Saturday 24th July 2021</Typography>
            <Typography className={classes.text}>1:30pm - 11pm</Typography>
            <Typography paragraph className={classes.text}>
              Attire: Formal
            </Typography>
            <Typography className={classes.text}>Gaynes Park</Typography>
            <address>
              <Typography className={classes.text}>Banks Lane Coopersale</Typography>
              <Typography className={classes.text}>Epping</Typography>
              <Typography className={classes.text}>Essex</Typography>
              <Typography paragraph className={classes.text}>
                CM16 7RJ
              </Typography>
            </address>
          </Grid>
          <Grid item container direction="column" className={classes.section}>
            <Typography variant="h3" className={classes.text} paragraph>
              What do I need to do?
            </Typography>
            <Grid className={classes.simpleStepsGrid} container direction="row" alignItems="center" justify="space-between">
              <Grid item>
                <Typography align="left" className={classes.text} paragraph>
                  1) Check the schedule and when you should arrive
                </Typography>
              </Grid>
              <Grid item className={matches ? classes.simpleStepsButtonParentDesktop : classes.simpleStepsButtonParentMobile}>
                <Link className={classes.buttonText} to="/schedule/">
                  <Button
                    className={matches ? classes.simpleStepsButtonParentDesktop : classes.simpleStepsButtonMobile}
                    variant="outlined"
                    color="primary"
                  >
                    Schedule
                  </Button>
                </Link>
              </Grid>
            </Grid>
            <Grid className={classes.simpleStepsGrid} container direction="row" alignItems="center" justify="space-between">
              <Grid item>
                <Typography align="left" className={classes.text} paragraph>
                  2) Find out where the venue is and book your transport and accomodation
                </Typography>
              </Grid>
              <Grid item className={matches ? classes.simpleStepsButtonParentDesktop : classes.simpleStepsButtonParentMobile}>
                <Link className={classes.buttonText} to="/accomodation-and-transport/">
                  <Button
                    className={matches ? classes.simpleStepsButtonParentDesktop : classes.simpleStepsButtonMobile}
                    variant="outlined"
                    color="primary"
                  >
                    Accomodation and Transport
                  </Button>
                </Link>
              </Grid>
            </Grid>
            <Grid className={classes.simpleStepsGrid} container direction="row" alignItems="center" justify="space-between">
              <Grid>
                <Typography align="left" className={classes.text} paragraph>
                  3) Check out some gift suggestions if you want to
                </Typography>
              </Grid>

              <Grid item className={matches ? classes.simpleStepsButtonParentDesktop : classes.simpleStepsButtonParentMobile}>
                <Link className={classes.buttonText} to="/gift-suggestions/">
                  <Button
                    className={matches ? classes.simpleStepsButtonParentDesktop : classes.simpleStepsButtonMobile}
                    variant="outlined"
                    color="primary"
                  >
                    Gift Suggestions
                  </Button>
                </Link>
              </Grid>
            </Grid>
            <Grid className={classes.simpleStepsGrid} container direction="row" alignItems="center" justify="space-between">
              <Grid item>
                <Typography align="left" className={classes.text} paragraph>
                  4) Please complete an RSVP for each member of your party
                </Typography>
              </Grid>
              <Grid item className={matches ? classes.simpleStepsButtonParentDesktop : classes.simpleStepsButtonParentMobile}>
                <a className={classes.buttonText} href="https://forms.gle/kzuV1s6A5JggVMzF7">
                  <Button
                    className={matches ? classes.simpleStepsButtonParentDesktop : classes.simpleStepsButtonMobile}
                    variant="outlined"
                    color="primary"
                  >
                    RSVP
                  </Button>
                </a>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </IndexLayout>
  )
}

export default IndexPage
